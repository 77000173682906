import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';

import {useLocation, useParams} from "react-router";
import {getQuicksightData, getQuicksightDataDetails} from "../../services/API";
import CircularProgress from "@mui/material/CircularProgress";
import {Divider, FilledInput, Grid, InputAdornment, InputBase, Paper, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DownloadIcon from '@mui/icons-material/Download';
import {Constants} from "./Constants";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";

let HEURISTIC = "HEURISTIC"
let STITCHING_VIEW = "STITCHING_VIEW"
let VOR_LOG = "VOR_LOG"
let CORRECTED_ERR = "CORRECTED_ERR"
let RESPONSE = "RESPONSE"
let ST_PACKAGE = "ST_PACKAGE"
let ST_BRAND = "ST_BRAND"
let ST_CORRECTIONS = "ST_CORRECTIONS"

let TEXT_FILE_TYPE = "Text"
let HTML_FILE_TYPE = ".html"

let JSON_FILE_TYPE = ".json"
let LOG_FILE_TYPE = ".log"
let downloadFileType = "";


let logData = "";
let textData = ""
let fileName = "";
const QuickSightDataDetails = () => {
    const location = useLocation();
    const [progress,setProgress] = useState(false);
    const [data,setData] = useState("");
    const [fileType,setFileType] = useState("")

    const[dc,setDC] = useState("")
    const[wrapper,setWrapper] = useState("")
    const[picklist,setPickList] = useState("")
    const[tDate,setTDate] = useState("")
    const[sysErr,setSysErr] = useState("")
    const[expWeight,setExpWeight] = useState("")
    const[actWeight,setActWeight] = useState("")
    const[delta,setDelta] = useState("")
    const[threshold,setThreshold] = useState("")
    const[expCC,setExpCC] = useState("")
    const[actCC,setActCC] = useState("")

    const [windowWidth,setWindowWidth] = useState(1000)

    useEffect(()=>{
        if(Constants.SIDE_MENU_OPEN){
            setWindowWidth(window.innerWidth-270)
        }else{
            setWindowWidth(window.innerWidth-30)
        }
    },[Constants.SIDE_MENU_OPEN]);

    useEffect(() => {
        try{
            document.getElementById("logs").style.display = "block";
            document.getElementById("users").style.display = "none";
            document.getElementById("admin").style.display = "none";

            console.log(location.search)
            console.log(Constants.selectedLog)
            setData("")
            if(location.search != ""){
                const searchParams = new URLSearchParams(location.search);
                let params = {};

                for (let param of searchParams) {
                    params[param[0]] = param[1];
                }
                console.log(params)
                Constants.PICKLIST = params.picklist
                Constants.CUST_ID = params.customer_id
                Constants.DC_ID = params.dc_id
                setPickList(params.picklist);
                setDC(params.dcname)
                setWrapper(params.pc)
                setTDate(params.trn_dt);
                setSysErr(params.sts);
                setExpWeight(params.exp_w);
                setActWeight(params.act_w);
                setDelta(params.delta);
                setThreshold(params.threshold);
                setExpCC(params.exp_cc);
                setActCC(params.act_cc);


            }
            if(Constants.selectedLog != Constants.DOWNLOAD_PICKLIST) {
                getData();
            }else{
                console.log("download picklist")
            }

        }catch (ex){
            console.log(ex.message);
        }
    }, [Constants.selectedLog]);

    let onMessage = ()=>{

    }

    let getData = async ()=>{

        try {
            setProgress(true);
            let fType = Constants.selectedLog;
            if(fType == STITCHING_VIEW){
                setFileType(HTML_FILE_TYPE);
            }else{
                setFileType(TEXT_FILE_TYPE);
            }
            if(fType == HEURISTIC || fType == RESPONSE || fType == ST_CORRECTIONS){
                downloadFileType = JSON_FILE_TYPE;
            }else if(fType == STITCHING_VIEW){
                downloadFileType = HTML_FILE_TYPE;
            }else{
                downloadFileType = LOG_FILE_TYPE;
            }



            logData = await getQuicksightDataDetails(Constants.PICKLIST,Constants.selectedLog,Constants.CUST_ID,Constants.DC_ID);
            console.log(logData);
            if(Constants.PICKLIST){
                try {
                    /*console.log(params.s3_url.split("/"))
                    let s3Array = params.s3_url.split("/");
                    if(s3Array && s3Array.length>0){
                        console.log(s3Array[3])
                        console.log(s3Array[4])
                        console.log(s3Array[7])
                        console.log(s3Array[8])
                        setDC(s3Array[3]);
                        setWrapper(s3Array[4]);
                        setPickList(s3Array[7]);
                        setTDate(s3Array[8]);
                    }*/
                    fileName = tDate+"_"+Constants.selectedLog+""+downloadFileType;
                }catch (ex){
                    console.log(ex.message)
                }

            }
            if(logData && logData.data){
                // fileName = params.data_type;
                if(Constants.selectedLog == HEURISTIC || Constants.selectedLog == RESPONSE || Constants.selectedLog == ST_CORRECTIONS){
                    //const obj = JSON.parse(logData.data.fc);

                    // console.log(JSON.stringify(obj, null, 2));
                    textData = JSON.stringify(logData.data,null,'\t');
                    setData(textData)
                }else{
                    textData = logData.data;
                    setData(textData)
                }

            }
            setProgress(false);
        }catch (ex){
            console.log(ex.message)
            setProgress(false);
        }
    }

    let onDownload = ()=>{
        console.log(textData)
        if(textData){
            setProgress(true);
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(textData));
            element.setAttribute('download', fileName);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            setProgress(false);
        }

    }

    let onSearchPicklist = ()=>{
        console.log(picklist)
        Constants.PICKLIST = picklist;
        setData("")
        getHeaderData();
        getData()

    }

    let getHeaderData = async ()=>{
        try{
            let logData = await getQuicksightDataDetails(Constants.PICKLIST,"HEAD",Constants.CUST_ID,Constants.DC_ID);
            console.log(logData);
            if(logData && logData.data && logData.data.main_header && logData.data.main_header != "NO PICKLIST FOUND"){
               // setPickList(params.picklist);
                setDC(logData.data.main_header[2])
                setWrapper(logData.data.main_header[3])
                setTDate(logData.data.main_header[4]);
                setSysErr(logData.data.main_header[5]);
                setExpWeight(logData.data.main_header[6]);
                setActWeight(logData.data.main_header[7]);
                setDelta(logData.data.main_header[8]);
                setThreshold(logData.data.main_header[9]);
                setExpCC(logData.data.main_header[10]);
                setActCC(logData.data.main_header[11]);
            }else{
                setDC("")
                setWrapper("")
                setTDate("");
                setSysErr("");
                setExpWeight("");
                setActWeight("");
                setDelta("");
                setThreshold("");
                setExpCC("");
                setActCC("");
            }
        }catch (ex){}
    }
    return (
        <>
            <div style={{ width: windowWidth }}>
                <div style={{position:"absolute",top:"50%",left:"50%"}}>
                    {progress?<CircularProgress />:""}
                </div>
                <div style={{border:"1px solid #ede7e7",marginBottom:"5px"}}>
                    <Grid container spacing={2} sx={{margin:"0px"}}>
                        <Grid xs={8} lg={8} xl={8}>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontSize"}>Distribution Center</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontSize"}>Wrapper Name</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontSize"}>Pick List Id</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontSize"}>Transaction Date</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontBold"}>{dc}</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontBold"}>{wrapper}</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Box sx={{ p: '2px 4px',marginTop:"5px",marginBottom:"5px", display: 'flex',
                                        width:"90%",height:"40px",border:"1px solid #f2eded", alignItems: 'center' }}>
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="picklist" value={picklist} onChange={(e)=>{
                                            console.log(e.target.value)
                                            setPickList(e.target.value)
                                        }}
                                            inputProps={{ 'aria-label': 'search google maps' }}
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearchPicklist}>
                                            <SearchIcon />
                                        </IconButton>

                                    </Box>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontBold"}>{tDate}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={2} lg={2} xl={2}>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid xs={12} lg={12} xl={12}>
                                    <Typography class={"qFontSize"}>Status</Typography>
                                </Grid>

                            </Grid>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid xs={12} lg={12} xl={12}>
                                    <Typography class={"qFontBold"}>{sysErr}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid xs={2} lg={2} xl={2} >
                            <IconButton color="primary"  onClick={onDownload}>
                                <DownloadIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{margin:"0px",marginTop:"10px",marginBottom:"10px"}}>
                        <Grid xs={12} lg={12} xl={12}>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Expected Weight : &nbsp;</Typography>
                                    <Typography class={"qFontBold"}>{expWeight}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Actual Weight : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {actWeight}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Delta : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {delta}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Threshold : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {threshold}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Expected Cases : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {expCC}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Actual Cases : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {actCC}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                {fileType == TEXT_FILE_TYPE?<textarea style={{width:"100%",fontSize:"14px",border:"1px solid #dfd9d9",height:(window.innerHeight-280)}} value={data} readOnly />:""}
                {fileType == HTML_FILE_TYPE? <div dangerouslySetInnerHTML={{__html: data}}></div>:""}
                {fileType == HEURISTIC?<textarea style={{width:"100%",fontSize:"14px",height:(window.innerHeight-280)}} value={data} readOnly />:""}

            </div>


        </>
    )
}



export default (QuickSightDataDetails);
